/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages                          
        
        // MENU ON MOBILE
        $(document).on('click','.navbar-collapse.in',function(e) {
            if( $(e.target).is('a') && $(e.target).attr('class') != 'dropdown-toggle' ) {
                $(this).collapse('hide');
            }
        });          
        
        // SLIDER
        $(document).ready(function(){         
          $('.produkty_slider').slick({
            
            dots: true,
            arrows: true,
            infinite: true,
            speed: 300,
            centerMode: true, 
            centerPadding: '25%',
            slidesToShow: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    centerPadding: '20%',
                    slidesToShow: 1,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    centerPadding: '10%',
                    slidesToShow: 1,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    centerPadding: '0px',
                    slidesToShow: 1,
                  }
                }            
            ]
          });
          $('.produkty_single_slider').slick({
            
            dots: true,
            arrows: true,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,                    
                  }
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,                                        
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, 
                  }
                }            
            ]
          });          
          $('.sklepy_slider').slick({
            
            dots: true,
            arrows: true,
            nextArrow: '<button type="button" class="slick-2-next"></button>',
            prevArrow: '<button type="button" class="slick-2-prev"></button>',            
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            speed: 300,
            centerMode: true,
//            variableWidth: true,
            autoplay: true,
            autoplaySpeed: 2000,  
            pauseOnFocus: false,
            pauseOnHover: true,
            responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,                    
                  }
                },
                {
                  breakpoint: 800,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,                                        
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, 
                  }
                }            
            ]            

          });          
        });        
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
        // MENU
        $('a[href*="#"]:not([href="#"])').click( function(event) {
                   event.preventDefault();
                 var anchor = 0;
                 if($(this.hash).offset().top > $(document).height()-$(window).height()){
                      anchor=$(document).height()-$(window).height();
                 }else{
                  anchor = $(this.hash).offset().top - 49 ;
                   }
                 $('html,body').stop().animate({scrollTop:anchor}, 1000,'swing');
             });
         $('.nav li a').click(function() {
            $(this).parent().find('a').removeClass('highlight');
            $(this).addClass('highlight');
        });
        $(window).scroll(function() {
            $('.section').each(function() {
                if($(window).scrollTop() >= $(this).offset().top - 51) {
                    var id = $(this).attr('id');
                    $('.nav li a').removeClass('highlight');
                    $('.nav li a[href=#'+ id +']').addClass('highlight');
                }
            });
        });        
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single': {
      init: function() {
        // JavaScript to be fired on the single page
        
        // SLIDER
        
        $(document).ready(function(){         
          $('.gallery-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1
          }); 
         
        });    
        
        // POPUP
        $(document).ready(function(){         
          
            /* custom settings */
            $('.venobox').venobox({
              bgcolor: '#fff', 
              closeColor: '#fff', 
              closeBackground: '#000000',
              spinColor: '#040ea4',
            });

        });          
        
        // TABS
        $(document).ready(function() {
          $("#sticker").stick_in_parent();          
          $('.tabs').tabslet({
            animation:    true,
            active:       1,
            container: '#tabs-content'
          });          
          $('.tabs').on("_before", function() {
            $('html, body').animate({
                scrollTop: $('#info').position().top },
                600,'swing'
            );         
          });          
        });                      
        
        // MATCH HEIGHT
        $(function() {
            $('.match-height').matchHeight();
        });                    
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
